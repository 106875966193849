import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoHeader from '../HomeTwo/HomeTwoHeader/HomeTwoHeader';
import HomeBrowse from '../HomeTwo/HomeBrowse/HomeBrowse';

const Browse = () => {
   return (
      <>
         <PageHelmet pageTitle="Browse" />
         <HomeTwoHeader/>
         <HomeBrowse/>
         <Footer/>
      </>
   );
};

export default Browse;