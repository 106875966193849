import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/HomeTwo/Home";
import Browse from "./pages/Browse/Browse";
import BrowseDetail from "./pages/BrowseDetail/BrowseDetail";
import '../src/App.css'

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/browse" element={<Browse/>} />
            <Route path="/browse-detail" element={<BrowseDetail/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
