import React from 'react';
import '../HomeBrowseDetail/HomeBrowseDetail.css';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

const HomeBrowseDetail = () => {

    const browseData = [
        { id: 1, Image: '/assets/img/browsedetail/BrowseDetail7.png', title: 'caption or hashtag of post' },
        { id: 2, Image: '/assets/img/browsedetail/BrowseDetail6.png', title: '#pink #flowers love these vibes' },
        { id: 3, Image: '/assets/img/browsedetail/BrowseDetail5.png', title: 'caption or hashtag of post' },
        { id: 4, Image: '/assets/img/browsedetail/BrowseDetail7.png', title: 'caption or hashtag of post' },
        { id: 5, Image: '/assets/img/browsedetail/BrowseDetail6.png', title: 'caption or hashtag of post' },
        { id: 6, Image: '/assets/img/browsedetail/BrowseDetail4.png', title: 'caption or hashtag of post' },
        { id: 7, Image: '/assets/img/browsedetail/BrowseDetail3.png', title: '#pink #flowers love these vibes' },
        { id: 8, Image: '/assets/img/browsedetail/BrowseDetail2.png', title: 'caption or hashtag of post' },
        { id: 9, Image: '/assets/img/browsedetail/BrowseDetail1.png', title: 'caption or hashtag of post' },
        { id: 10, Image: '/assets/img/browsedetail/BrowseDetail3.png', title: 'caption or hashtag of post' },
    ];

    return (
        <>
            <section className="pt-135 pb-40 browse-detail">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-30">
                            <div className="back-page-link">
                                <Link to="/browse">
                                    <img src="assets/img/browsedetail/Arrow-Left.png" alt="browsedetail" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-5 col-xl-3 col-lg-3 col-sm-6">
                            <div className="browse-main-image">
                                <img style={{width:'100%'}} src="assets/img/browsedetail/Art.png" alt="browsedetail" />
                            </div>
                        </div>
                        <div className="col-md-7 col-xl-9 col-lg-9 col-sm-6">
                            <div className="browse-main-title">
                                <h1>Art</h1>
                                <p>Lorem ipsum dolor sit amet consectetur. Diam tortor bibendum magnis volutpat eget at vestibulum eget luctus. Etiam egestas lectus enim rhoncus cursus vel at nibh. Scelerisque varius faucibus lorem vestibulum a. Turpis massa magna velit risus aliquam sit tincidunt massa amet. Vitae iaculis lobortis arcu egestas cras in sit donec.</p>
                            </div>
                        </div>
                        <div className="col-12 mb-40 mt-40">
                            <h2>Most Popular</h2>
                        </div>
                        {browseData.map((item) => (
                            <div key={item.id} className="col-xl-20 col-lg-3 col-md-4 col-sm-6 col-6">
                                <div
                                    className="browse-image-detail mb-30"
                                >
                                    <Card className='border-0'>
                                        <div className="position-relative">
                                            <Card.Img variant="top" src={item.Image} alt={`browsedetail-${item.id}`} />
                                            <div className="slide-number">
                                                <p>4/9</p>
                                            </div>
                                        </div>
                                        <Card.Body className='ps-0 pe-0'>
                                            <Card.Title>{item.title}</Card.Title>
                                            <div className="description-wrapper" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div className="personal-info">
                                                    <div className="person-avatar">
                                                        <img src="assets/img/browsedetail/Avatar.png" alt="browsedetail" />
                                                    </div>
                                                    <div className="person-name">
                                                        <p>radwaaly</p>
                                                    </div>
                                                </div>
                                                <div className="play-icon">
                                                    <img src="assets/img/browsedetail/Play.png" alt="browsedetail" />
                                                    <p>19.6k</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeBrowseDetail;