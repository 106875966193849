import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoExpertArea from '../HomeTwo/HomeTwoExpertArea/HomeTwoExpertArea';
import AboutSection from '../HomeTwo/AboutSection/AboutSection';
import ContactArea from '../Contact/ContactArea/ContactArea';
import AboutHeader from '../HomeTwo/AboutHeader/AboutHeader';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="About" />
         <AboutHeader/>
         <CommonPageHeader title="about" subtitle="About" />
         <HomeTwoExpertArea/>
         <AboutSection/>
         <ContactArea/>
         <Footer/>
      </>
   );
};

export default About;