import React, { useState } from 'react';
import Slider from 'react-slick';
import './HomeTwoTestimonial.css';
import { Link } from 'react-router-dom';
import Browse1 from './Testimonial/Browse1.png';
import Browse2 from './Testimonial/Browse2.png';
import Browse3 from './Testimonial/Browse3.png';
import Browse4 from './Testimonial/Browse4.png';
import Browse5 from './Testimonial/Browse5.png';
import Browse6 from './Testimonial/Browse1.png';

const HomeTwoTestimonial = () => {
   const [currentSlide, setCurrentSlide] = useState(0);
   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: true,
      fade: false,
      arrows: false,
      slidesToShow: 5,
      centerMode: true,
      centerPadding: '0px',
      speed:300,
      variableHeight: true,
      responsive: [
         {
            breakpoint: 1500,
            settings: {
               arrows: false,
               centerMode: true,
               centerPadding: '20px',
               slidesToShow: 3,
               variableHeight: true,
            },
         },
         {
            breakpoint: 991,
            settings: {
               arrows: false,
               centerMode: true,
               centerPadding: '20px',
               slidesToShow: 1,
               variableHeight: true,
            },
         },
         {
            breakpoint: 768,
            settings: {
               arrows: false,
               centerMode: true,
               centerPadding: '40px',
               slidesToShow: 1,
               variableHeight: true,
            },
         },
      ],
      afterChange: (current) => setCurrentSlide(current),
   };

   const testimonialData = [
      {
         id: 1,
         title: 'Nature',
         Browse: Browse1
      },
      {
         id: 2,
         title: 'Art',
         Browse: Browse2
      },
      {
         id: 3,
         title: 'Family',
         Browse: Browse3
      },
      {
         id: 4,
         title: 'Travel',
         Browse: Browse4
      },
      {
         id: 5,
         title: 'Animal',
         Browse: Browse5
      },
      {
         id: 6,
         title: 'Food',
         Browse: Browse6
      }
   ];

   return (
      <>
         <section className="testimoinal__area">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-xl-12 col-lg-12 p-0">
                     <div className="testimonial__content pt-15 pb-15">
                        <Slider className="testimonial__slider" {...settings}>
                           {testimonialData.map((testimonial, index) => {
                              const isCurrentSlide = index === currentSlide;
                              return (
                                 <div key={index} className={`testimonial__item ${isCurrentSlide ? 'slick-current' : ''}`}>
                                    <div className="d-flex align-items-center position-relative image-wrapper">
                                       {testimonial.Browse && (
                                          <img src={testimonial.Browse} alt={`testimonial-browse-${testimonial.id}`} />
                                       )}
                                       <div className="testimonial__info-overlay">
                                          <h4 className="white-color">{testimonial.title}</h4>
                                          {isCurrentSlide && (
                                             <Link to="/browse">
                                                <div className='browse-image-hover-inner'>
                                                   <button href="#" className="see-more-link p-0">See More</button>
                                                   <img src="assets/img/browse/Browse-Arrow.png" alt="see-more-arrow" />
                                                </div>
                                             </Link>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}
                        </Slider>
                     </div>
                  </div>
               </div>
            </div>
         </section>

      </>
   );
};

export default HomeTwoTestimonial;