import React from 'react';
import { Link } from 'react-router-dom';
import '../HomeTwoFaq/HomeTwoFaq.css';
import FunnyImage from './HomeTwoFaq/Funny.png';

const HomeTwoFaq = () => {

   return (
      <>
         <section className="achievement__area home-download pt-135 pb-40">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6 col-md-12 d-flex align-items-center order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2">
                     <div>
                        <Link to="/about" className="z-btn">Lorem</Link>
                        <h3 className='mb-15 mt-15'>Download & Enjoy Seezitt</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempor fames amet tempus vel. Sagittis malesuada quam sollicitudin enim. Placerat quis imperdiet amet sed ut id morbi imperdiet. At ut urna.</p>
                        <div className="download-section mb-30">
                           <ul>
                              <li>
                                 <Link to="#" rel="noopener noreferrer">
                                    <img src="assets/img/footer/Darkbadge1.png" alt="" />
                                 </Link>
                              </li>
                              <li>
                                 <Link to="#" rel="noopener noreferrer">
                                    <img src="assets/img/footer/Darkbadge2.png" alt="" />
                                 </Link>
                              </li>
                              <li>
                                 <Link to="#" rel="noopener noreferrer">
                                    <img src="assets/img/footer/Darkbadge3.png" alt="" />
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 col-lg-6 col-md-12 offset-xl-2 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1">
                     <div className="achievement__content smartphpne-wrapper">
                        <div className="achievement__wrapper d-flex d-md-block d-lg-flex justify-content-between mb-35 wow fadeInUp" data-wow-delay=".8s">
                           <div className="achievement__item mb-30">
                              <img style={{ width: '100%' }} src={FunnyImage} alt="achievement" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoFaq;