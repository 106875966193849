import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const Sidebar = ({ show, handleClose }) => {

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  <div className="logo">
                     <Link to="/">
                        <img src="assets/img/logo/logo.svg" alt="logo" />
                     </Link> 
                  </div>
               </Offcanvas.Header>

               <Offcanvas.Body>


                  <section>
                     <div className="p-0">
                        <div className="sidebar__tab">
                           <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                              <li className="nav-item">
                                 <Link className="nav-link active" id="menu-tab" data-bs-toggle="tab" to="#menu" role="tab" aria-controls="menu" aria-selected="true">menu</Link> 
                              </li>
                              <li className="nav-item">
                                 <Link className="nav-link" id="info-tab" data-bs-toggle="tab" to="#info" role="tab" aria-controls="info" aria-selected="false">info</Link> 
                              </li>
                           </ul>
                        </div>
                        <div className="sidebar__content">

                           <div className="tab-content" id="sidebar-tab-content">
                              <div className="tab-pane fade show active" id="menu" role="tabpanel" aria-labelledby="menu-tab">

                                 <div className='side_navBar'>
                                    <div className='about iconAdd border-1'>
                                       <NavLink to="/">Home </NavLink>
                                    </div>
                                    <div className='about iconAdd border-1'>
                                       <NavLink to="/about">About </NavLink>
                                    </div>

                                    <div className='about iconAdd border-1'>
                                       <NavLink to="/browse">Browse </NavLink>
                                    </div>

                                    <div className='about iconAdd border-1'>
                                       <NavLink to="/browse">Community Headlines </NavLink>
                                    </div>

                                    <Collapsible trigger={<NavLink to="/portfolio">Features</NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white text-capitalize">
                                          <li><NavLink to="/ar-studio">AR Studio</NavLink></li>
                                          <li><NavLink to="/security">Security</NavLink></li>
                                          <li><NavLink to="/blog">Blog</NavLink></li>
                                       </ul>
                                    </Collapsible>

                                 </div>

                              </div>

                              <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                                 <div className="sidebar__info">
                                    <div className="logo mb-40">
                                       <Link to="/">
                                          <img src="assets/img/logo/logo.svg" alt="logo"/>
                                       </Link> 
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare cursus sed nunc eget dictum  Sed ornare cursus sed nunc eget dictumd nunc eget dictum  Sed ornare cursus sed nunc eget dictum.</p>
                                    <Link to="/contact" className="z-btn z-btn-white">contact us</Link> 
                                    <div className="sidebar__contact mt-30">
                                       <ul>
                                          <li>
                                             <div className="icon">
                                                <i > <BiMap /> </i>
                                             </div>
                                             <div className="text">
                                                <span>305 Vineyard Town Center #325, , Morgan Hill, CA 95037 USA</span>
                                             </div>
                                          </li>
                                          <li>
                                             <div className="icon">
                                                <i > <FaEnvelope /> </i>
                                             </div>
                                             <div className="text ">
                                                <span><a href="mailto:info@poshenterpriseinc.com">info@poshenterpriseinc.com</a></span>
                                             </div>
                                          </li>
                                          <li>
                                             <div className="icon">
                                                <i ><FaPhoneAlt /> </i>
                                             </div>
                                             <div className="text">
                                                <span><a href="tel:+1 (408)-807-6383">+1 (408)-807-6383</a></span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;