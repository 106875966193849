import React from "react";
import "../ContactArea/ContactArea.css";

const ContactArea = () => {
    return (
        <>
            <section className="contact__area contact-seezitt pb-80 pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="accordion-title mb-50">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row contact-form-wrapper wow fadeInUp" data-wow-delay=".3s">
                        <div className="col-xl-6 text-lg-center">
                            <div className="contact__info">
                                <div className="text-center">
                                    <h2>Keep in Touch</h2>
                                    <p>Our team is always happy to hear from you and get back to you. Reach out to us whenever you wish, your opinion greatly matters.</p>
                                </div>
                                <ul>
                                    <li>
                                        <h4>
                                            <img className="mr-15" src={process.env.PUBLIC_URL + "/assets/img/contact/Message.png"} alt="Message" />
                                            Email Address
                                        </h4>
                                        <p>
                                            <a href="mailto:info@poshenterpriseinc.com">info@poshenterpriseinc.com</a>
                                        </p>
                                    </li>
                                    <li>
                                        <h4>
                                            <img className="mr-15" src={process.env.PUBLIC_URL + "/assets/img/contact/Calling.png"} alt="Calling" />
                                            Phone Number
                                        </h4>
                                        <p>
                                            <a href="tel:+1 (408)-807-6383">+1 (408)-807-6383</a>
                                        </p>
                                    </li>
                                    <li>
                                        <h4>
                                            <img className="mr-15" src={process.env.PUBLIC_URL + "/assets/img/contact/Location.png"} alt="Location" />
                                            Address
                                        </h4>
                                        <p>
                                            305 Vineyard Town Center #325, <br /> Morgan Hill, CA 95037 USA
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-60 d-flex align-items-center">
                            <div className="contact__form">
                                <h2>Drop us a line</h2>
                                <p>Please feel free to contact us, If you have any further concerns</p>
                                <form>
                                    <div className="mb-3">
                                        <input type="text" id="name" autoComplete="name" className="form-control" required placeholder="Name" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" id="email" autoComplete="email" className="form-control" required placeholder="Email" />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className="form-control" autoComplete="message" id="message" required placeholder="Your Message"></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary w-100">
                                        Send Message
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-12 d-none d-md-none d-sm-none d-lg-none d-xl-block">
                            <div className="vl"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;
