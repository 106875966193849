import React from 'react';
import '../Work/work.css';

const Work = () => {
    const workData = [
        {
            image: 'assets/img/faq/Money.svg',
            title: 'Earn money via Coins',
            description: 'Users can give virtual gifts that are bought by coins and individual coins to live streamers. App earns a commission every time a user sends these virtual gifts to streamers.',
        },
        {
            image: 'assets/img/faq/Streaming.svg',
            title: 'Live streaming',
            description: 'Lorem ipsum dolor sit amet consectetur. Porta enim ipsum volutpat non morbi tellus id rhoncus aliquet. Purus lobortis odio et eros. Pellentesque elit purus ultrices tellus donec.',
        },
        {
            image: 'assets/img/faq/Coin.svg',
            title: 'Viewers buy coins',
            description: 'Lorem ipsum dolor sit amet consectetur. Porta enim ipsum volutpat non morbi tellus id rhoncus aliquet. Purus lobortis odio et eros. Pellentesque elit purus ultrices tellus donec.',
        },
        {
            image: 'assets/img/faq/Advertising.svg',
            title: 'In-app advertising',
            description: 'Lorem ipsum dolor sit amet consectetur. Porta enim ipsum volutpat non morbi tellus id rhoncus aliquet. Purus lobortis odio et eros. Pellentesque elit purus ultrices tellus donec.',
        },
    ];

    return (
        <div className='work-bg'>
            <div className='container'>
                <div className="row">
                    <div className="col-md-12 mt-80 accordions-faqs">
                        <div className='accordion-title mb-50'>
                            <h2>How it works</h2>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row mb-80">
                            {workData.map((item, index) => (
                                <div key={index} className="col-md-6">
                                    <div className="card mb-40">
                                        <div className="card-image-wrapper">
                                            <img className="card-img-left example-card-img-responsive mr-20" src={item.image} alt="" />
                                            <h5 className="card-title h5 h4-sm">{item.title}</h5>
                                        </div>
                                        <div className="card-body p-0">
                                            <p className="card-text">{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Work;