import React from 'react';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoHeader from '../HomeTwo/HomeTwoHeader/HomeTwoHeader';
import HomeBrowseDetail from '../HomeTwo/HomeBrowseDetail/HomeBrowseDetail';

const BrowseDetail = () => {
   return (
      <>
         <PageHelmet pageTitle="Browse Title" />
         <HomeTwoHeader/>
         <HomeBrowseDetail/>
         <Footer/>
      </>
   );
};

export default BrowseDetail;