import React from 'react';
import { Link } from 'react-router-dom';
import '../HomeTwoHeroSlider/HomeTwoHeroSlider.css'
import HomeTwoTestimonial from '../HomeTwoTestimonial/HomeTwoTestimonial';
import Smartphone from './HomeTwoHero/Smartphone.png'

const HomeTwoHeroSlider = () => {

   return (
      <>
         <div className='banner_heading'>
            <h1>A platform to make everyone happy</h1>  
            <p>Lorem ipsum dolor sit amet consectetur. Pulvinar amet at sapien eu gravida condimentum venenatis. Nullam nec donec lis.</p>
         </div>
         <HomeTwoTestimonial />
         <section className="achievement__area home-about pt-135 pb-40">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12">
                     <div className="achievement__content smartphpne-wrapper">
                        <div className="achievement__wrapper d-flex d-md-block d-lg-flex justify-content-between mb-35 wow fadeInUp" data-wow-delay=".8s">
                           <div className="achievement__item mb-30">
                              <img style={{ width: '100%' }} src={Smartphone} alt="achievement" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center">
                     <div>
                        <Link to="/about" className="z-btn">About</Link>
                        <h3 className='mb-30 mt-30'>Seezitt is the leading destination for short-form mobile video. Our mission is to inspire creativity and bring joy.</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempor fames amet tempus vel. Sagittis malesuada quam sollicitudin enim. Placerat quis imperdiet amet sed ut id morbi imperdiet. At ut urna.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoHeroSlider;