import React from 'react';
import '../CommonPageHeader/CommonPageHeader.css';

const CommonPageHeader = ({ title, subtitle }) => {
   return (
      <>
         <section className="page__title p-relative d-flex align-items-center fix" style={{ background: `url(assets/img/page-title/About-banner.png)`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-12 mt-100 text-center">
                     <div className="page__title-content mt-100">
                        <h2 className='mb-30'>About Seezitt</h2>
                        <p>Lorem ipsum dolor sit amet consectetur. Pulvinar amet at sapien eu gravida condimentum venenatis. Nullam nec donec lis.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonPageHeader;