import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import ContactArea from './ContactArea/ContactArea';import HomeTwoHeader from '../HomeTwo/HomeTwoHeader/HomeTwoHeader';

const Contact = () => {
   return (
      <>
         <PageHelmet pageTitle="Contact Page" />

         <HomeTwoHeader/>
         <CommonPageHeader title="Contact Us" subtitle="Contact" />
         <ContactArea/>
         <Footer/>
      </>
   );
};

export default Contact;