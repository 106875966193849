import React from 'react';
import '../shared/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
   return (
      <>
           <footer>
            <div className="footer__area footer-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 order-md-3 order-3 order-xl-1 order-lg-1">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <Link to="/">
                                       <img src="assets/img/logo/logo1.svg" alt=""/>
                                    </Link>
                                 </div>
                              </div>
                              <div className="footer__widget-content">
                                 <p className="mb-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ornare cursus sed nunc eget dictum  Sed ornare cursus sed nunc eget dictumd nunc eget dictum  Sed ornare cursus sed nunc eget dictum</p>
                                 <div className="footer__social theme-social mb-30">
                                    <ul>
                                       <li>
                                          <img src="assets/img/footer/badge3.png" alt=""/>
                                       </li>
                                       <li>
                                          <img src="assets/img/footer/badge2.png" alt=""/>
                                       </li>
                                       <li>
                                          <img src="assets/img/footer/badge1.png" alt=""/>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 offset-xl-1 order-md-1 order-1 order-xl-2 order-lg-2">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><Link to="/">Home</Link></li>
                                       <li><Link to="/">About</Link></li>
                                       <li><Link to="/">Browse</Link></li>
                                       <li><Link to="/">Community Guidelines</Link></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 order-md-2 order-2 order-xl-3 order-lg-3">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><Link to="/">Features</Link></li>
                                       <li><Link to="/">AR Studio</Link></li>
                                       <li><Link to="/">Security</Link></li>
                                       <li><Link to="/">Blog</Link></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;