import React from 'react';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoFaq from './HomeTwoFaq/HomeTwoFaq';
import HomeTwoHeader from './HomeTwoHeader/HomeTwoHeader';
import HomeTwoHeroSlider from './HomeTwoHeroSlider/HomeTwoHeroSlider';
import Footer from '../../components/shared/Footer';
import Faqs from '../../components/FAQS/Faqs';
import ContactArea from '../Contact/ContactArea/ContactArea';
import Work from '../../components/Work/Work';

const Home = () => {
   return (
      <>
         <PageHelmet pageTitle="Home" />
         <HomeTwoHeader/>
         <HomeTwoHeroSlider/>
         <Work/>
         <HomeTwoFaq/>
         <ContactArea/>
         <Faqs/>
         <Footer/>
      </>
   );
};

export default Home;