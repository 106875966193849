import React, { useState } from 'react';
import '../HomeBrowse/HomeBrowse.css';
import { Link } from 'react-router-dom';

const HomeBrowse = () => {
    const [hoveredItem, setHoveredItem] = useState(null);

    const browseData = [
        { id: 1, Image: '/assets/img/browse/Nature.png', title: 'Nature' },
        { id: 2, Image: '/assets/img/browse/Art.png', title: 'Art' },
        { id: 3, Image: '/assets/img/browse/Family.png', title: 'Family' },
        { id: 4, Image: '/assets/img/browse/Travel.png', title: 'Travel' },
        { id: 5, Image: '/assets/img/browse/Animal.png', title: 'Animal' },
        { id: 6, Image: '/assets/img/browse/Food.png', title: 'Food' },
        { id: 7, Image: '/assets/img/browse/Auto.png', title: 'Auto' },
        { id: 8, Image: '/assets/img/browse/Craft.png', title: 'Craft' },
        { id: 9, Image: '/assets/img/browse/Babies.png', title: 'Babies' },
        { id: 10, Image: '/assets/img/browse/Graphic-Design.png', title: 'Graphic Design' },
        { id: 11, Image: '/assets/img/browse/Magic-Tricks.png', title: 'Magic Tricks' },
        { id: 12, Image: '/assets/img/browse/Sports.png', title: 'Sports' },
        { id: 13, Image: '/assets/img/browse/Outdoor-Activities.png', title: 'Outdoor Activities' },
        { id: 14, Image: '/assets/img/browse/Learn.png', title: 'Learn' },
        { id: 15, Image: '/assets/img/browse/Society.png', title: 'Society' },
        { id: 16, Image: '/assets/img/browse/Daily-Life.png', title: 'Daily Life' },
        { id: 17, Image: '/assets/img/browse/Comedy.png', title: 'Comedy' },
        { id: 18, Image: '/assets/img/browse/Self-Love.png', title: 'Self Love' },
        { id: 19, Image: '/assets/img/browse/Technology.png', title: 'Technology' },
        { id: 20, Image: '/assets/img/browse/Health.png', title: 'Health' },
        { id: 21, Image: '/assets/img/browse/Talent.png', title: 'Talent' },
        { id: 22, Image: '/assets/img/browse/Fashion.png', title: 'Fashion' },
        { id: 23, Image: '/assets/img/browse/Beauty.png', title: 'Beauty' },
        { id: 24, Image: '/assets/img/browse/Entertainment.png', title: 'Entertainment' },
    ];

    return (
        <>
            <section className="achievement__area pt-135 pb-40">
                <div className="container">
                    <div className="row">
                        {browseData.map((item) => (
                            <div key={item.id} className={`col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 ${hoveredItem === item.id ? 'hovered' : ''}`}>
                                <div
                                    className="browse-image image-wrapper mb-30"
                                    onMouseEnter={() => setHoveredItem(item.id)}
                                    onMouseLeave={() => setHoveredItem(null)}
                                >
                                    <img src={item.Image} alt={`achievement-${item.id}`} />
                                    <div className='browse-image-hover'>
                                        <h4 className='browse-title'>{item.title}</h4>
                                        {hoveredItem === item.id && (
                                            <Link to="/browse-detail">
                                                <div className="browse-image-hover-inner">
                                                    <button>
                                                        See more
                                                    </button>
                                                    <img src="assets/img/browse/Browse-Arrow.png" alt="achievement" />
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default HomeBrowse;
