import React from 'react';
import { Link } from 'react-router-dom';
import '../AboutSection/AboutSection.css'

const AboutSection = () => {

   return (
      <>
         <div className="vision-bg pt-50 pb-50">
            <div className='container'>
               <div className="row">
                  <div className="col-12 accordion-title">
                     <div className="vision-heading mb-40">
                        <h2>Our Vision</h2>
                     </div>
                     <div className="vision-para">
                        <p>Lorem ipsum dolor sit amet consectetur. Eget imperdiet augue suspendisse consequat. Viverra aliquet dui amet vulputate sed at nisl.Lorem ipsum dolor sit amet consectetur. Tempor fames amet tempus vel. Sagittis malesuada quam sollicitudin enim. Placerat quis imperdiet amet sed ut id morbi imperdiet. At ut urna.Lorem ipsum dolor sit amet consectetur. Tempor fames amet tempus vel. Sagittis malesuada quam sollicitudin enim. Placerat quis imperdiet amet sed ut id morbi imperdiet. At ut urna.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <section className="achievement__area home-about pb-40">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2">
                     <div>
                        <Link to="/about" className="z-btn">Our Mission</Link>
                        <h3 className='mb-30 mt-30'>Seezitt is the leading destination for short-form mobile video. Our mission is to inspire creativity and bring joy.</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempor fames amet tempus vel. Sagittis malesuada quam sollicitudin enim. Placerat quis imperdiet amet sed ut id morbi imperdiet. At ut urna.</p>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1">
                     <div className="achievement__content">
                        <div className="achievement__wrapper d-flex d-md-block d-lg-flex justify-content-between wow fadeInUp" data-wow-delay=".8s">
                           <div className="achievement__item pe-0">
                              <img style={{width:'100%'}} src="assets/img/smartphone/Iphone15.png" alt="achievement" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default AboutSection;